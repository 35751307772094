/**
 *  Load jQuery and start Style Guide setup.
 */
document.addEventListener('DOMContentLoaded', () => {
  loadjQueryScript(styleguideSetup);
});

/**
 * Setup Styleguide functions.
 */
function styleguideSetup() {
  $( '.nlt-click-prevent-default' ).on('click', function(event) {
    event.preventDefault();
  });

  $('.js-rl-login-btn').on('click', function(event) {
    event.preventDefault();
    $('#account_link_nh').trigger('click');
  });

  if ( $('.nlt__slider-slick-slider, .nlt-one-piece-four-ways-slider').length > 0 ) {
    loadSliderScript(slidersSetup);
  }
}

/**
 * Setup sliders with Slick Slider.
 */
function slidersSetup() {
  $('.nlt-one-piece-four-ways-slider, .nlt__slider-slick-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  });
}

/**
 * Load jQuery script.
 *
 * @param {Function} callback Function to be called after script loaded.
 */
function loadjQueryScript(callback) {
  if ( typeof jQuery === 'undefined' || typeof $ === 'undefined' ) {
    const script = document.createElement('script');
    script.src = '//code.jquery.com/jquery-3.6.0.min.js';
    script.addEventListener('load', callback);
    document.head.appendChild(script);
    return;
  }
  callback();
}

/**
 * Load Slick Slider script.
 *
 * @param {Function} callback Function to be called after script loaded.
 */
function loadSliderScript( callback ) {
  const script = document.createElement('script');
  script.src = '//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.min.js';
  script.addEventListener('load', callback);
  document.head.appendChild(script);
}
